import './style.scss';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Parser from 'html-react-parser';
import {
    updateHeaderStatus,
    updateFooterStatus,
    updateConfigHeaderFooter,
    updateConfigCampaign,
} from '../../../actions';

import { learningContentsTracking, learningContentUpdate, recordTracking } from '../../../services/api';

import { useHistory } from 'react-router-dom';

import { useScrolledToEdge } from 'scrolled-to-edge';

function ContentSite(props) {
    const history = useHistory();
    const { data } = props;

    const _recordTracking = async (_type: string | null = null) => {
        if (props.dataParentObj.contentEnrollment) return false;
        // id contentType existe somente 2 (valores 1: conteudo sozinho, valor 2: video/audi dentro do html(frame))
        const timeStart = 0;
        const navItem = props.data.idContentItem;
        const navType = 1;
        const idSection = props.data.idSection;
        const timeExecuted = 0;

        // console.log(timeStart, navItem, navType, idSection, timeExecuted);
        const res = await recordTracking(timeStart, navItem, navType, idSection, timeExecuted);

        const earnPointsStatus = res.data.earnPoints && props.data.completed === 0;
        //const earnPointsStatus = true;

        props.updateConfigCampaign({
            earnPointsStatus: _type === 'WillUnmount' ? false : earnPointsStatus,
        });
    };

    const onScrollContentEnd = async () => {
        if (props.data.idContentEnrollment && props.dataParentObj.contentEnrollment.status != 'COMPLETED') {
            if (props.data.idContentEnrollment) {
                const objTrackingContent = {
                    status: 'COMPLETED',
                    grade: 0,
                    progress: 100,
                };

                await learningContentUpdate(objTrackingContent, props.data.idContentEnrollment);
            }

            if (props.data.idEnrollment) {
                const objTrackingContent = {
                    idEnrollment: props.data.idEnrollment,
                    type: 'ACCESS_CONTENT',
                    progress: 100,
                };

                await learningContentsTracking(objTrackingContent, props.data.idContentItem);
            }
        }

        props.updateConfigHeaderFooter({
            logoStyle: data.text ? `hide` : 'show',
            titleHeader: data.text ? `${data.text}` : null,
            prevNavStyle: 'closeShow',
            footerStyle: 'hide',
            contentNavigation: props.dataParentObj,
            unlockNext: true,
            backCloseText: 'FECHAR CONTEÚDO',
        });

        _recordTracking();
    };

    useEffect(() => {
        // updateHeader(getDesign(idSection, "general")?.showTopBar || "true");

        const { updateConfigHeaderFooter, params } = props;
        updateConfigHeaderFooter({
            logoStyle: data.text ? `hide` : 'show',
            titleHeader: data.text ? `${data.text}` : null,
            prevNavStyle: 'closeShow',
            footerStyle: 'hide',
            contentNavigation: props.dataParentObj,
            unlockNext: true,
            backCloseText: 'FECHAR CONTEÚDO',
            single: params.scoType === 'single',
        });

        if (!Boolean(data.contentLocation)) {
            history.goBack();
        }

        setTimeout(() => {
            onScrollContentEnd();
        }, 2000);

        return () => {
            _recordTracking('WillUnmount');
        };
    }, []);

    const container = useScrolledToEdge((e) => {}, 0);

    return (
        <div className="pctPage ptcSinglePage scrolling-container" ref={container}>
            <div id="elementBody" className="htmlContentBody">
                {data.author !== '' && <div className="singlePageHeader">Autor - {Parser(data.author)}</div>}

                {Parser(data.content)}
            </div>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => ({
    updateFooterStatus: (item) => dispatch(updateFooterStatus(item)),
    updateHeaderStatus: (item) => dispatch(updateHeaderStatus(item)),
    updateConfigHeaderFooter: (item) => dispatch(updateConfigHeaderFooter(item)),
    updateConfigCampaign: (item) => dispatch(updateConfigCampaign(item)),
});

export default connect(null, mapDispatchToProps)(ContentSite);
