import './style.scss';

import React, { useState, useEffect } from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { useModal } from '@ebay/nice-modal-react';

import { updateConfigHeaderFooter } from '../../../actions';

import ModalSyllabus from 'pctComponents/modals/ModalSyllabus';

import { removeTagsHtml } from 'services/utils';
import { InteractionDrawer } from 'pctComponents/organisms';
import { DrawerContainer } from 'pctComponents/organisms/InteractionDrawer/InteractionDrawer.types';
import useMediaQuery from 'pctHooks/useMediaQuery';
import AttachmentSideBar from 'pctComponents/organisms/AttachmentSideBar';

import { CreateDrawerContainer } from './components/CreateDrawerContainer';
import { MenuHeaderContentView } from './components/MenuHeaderContentView';

type Props = { nodeCollection: BlockElement; configHeaderFooter: any };
type collectionProps = { data: DataBlockElement };

const MenuHeaderContent = ({ nodeCollection, configHeaderFooter }: Props) => {
    const isMobile = useMediaQuery('md');
    const userModal = useModal(ModalSyllabus);
    const [collection, setCollection] = useState<collectionProps | null>(null);
    const [drawerContainer, setDawerContainer] = useState<DrawerContainer>({} as DrawerContainer);
    // const [singleLevelContent, setSingleLevelContent] = useState(false);
    const [isOpenComments, setIsOpen] = useState<boolean>(false);
    const [showInteractionDrawerButton, setShowInteractionDrawerButton] = useState(false);
    const [isOpenAttachmentSideBar, setIsOpenAttachmentSideBar] = useState<boolean>(false);
    const [attachmentStatus, setAttachmentStatus] = useState<null | boolean>(null);
    const [idTeam, setIdTeam] = useState<null | number>(null);

    useEffect(() => {
        if (nodeCollection && nodeCollection.node) {
            const { allowComments, commentsLabel, nodeCode, nodeEnrollment } = nodeCollection.node;

            const commentsObj = {
                node: {
                    allowComments: allowComments || null,
                    commentsLabel: commentsLabel || null,
                    idEntity: `${nodeCode || null}`,
                },
                team: {
                    allowComments: nodeEnrollment?.team?.allowComments || null,
                    commentsLabel: nodeEnrollment?.team?.commentsLabel || null,
                    idEntity: `${nodeEnrollment?.team?.idTeam || null}`,
                },
            };

            const { showInteractionDrawer, drawerContainer } = CreateDrawerContainer(commentsObj, isMobile);
            setShowInteractionDrawerButton(showInteractionDrawer);
            setDawerContainer(drawerContainer);
            setCollection({ data: nodeCollection.node });
            // setSingleLevelContent(Boolean(nodeCollection.levelSingle)); // Verifica se ocorreu um redirecionamento do level para um conteúdo
            setAttachmentStatus(Boolean(nodeCollection?.node?.nodeEnrollment?.team?.hasAttachments));
            nodeCollection?.node?.nodeEnrollment?.team?.idTeam && setIdTeam(nodeCollection?.node?.nodeEnrollment?.team?.idTeam);
        } else {
            if (configHeaderFooter.single) {
                const contentEnrollment = configHeaderFooter.contentNavigation.contentEnrollment;
                const team = configHeaderFooter.contentNavigation.team[0];
                const nodeCode = team.nodeCode;
                const idTeam = team.idTeam;

                const commentsObj = {
                    node: {
                        allowComments: contentEnrollment.allowCommentsInNode || null,
                        commentsLabel: contentEnrollment.commentsLabelInNode || null,
                        idEntity: `${nodeCode || null}`,
                    },
                    team: {
                        allowComments: team.allowComments || null,
                        commentsLabel: team.commentsLabel || null,
                        idEntity: `${idTeam || null}`,
                    },
                };

                const { showInteractionDrawer, drawerContainer } = CreateDrawerContainer(commentsObj, isMobile);
                setShowInteractionDrawerButton(showInteractionDrawer);
                setDawerContainer(drawerContainer);

                const hasAttachments = Boolean(contentEnrollment.hasAttachments);
                setAttachmentStatus(hasAttachments);
                setIdTeam(idTeam);
            }
        }

        return () => {
            setAttachmentStatus(null);
            setIdTeam(null);
            setShowInteractionDrawerButton(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nodeCollection, configHeaderFooter.single, isMobile]);

    // console.log('nodeCollection --> ', nodeCollection);

    const callSyllabus = (e) => {
        e.stopPropagation();
        userModal.show({ collection });
    };

    const syllabusStatus =
        nodeCollection &&
        collection &&
        collection.data.syllabus &&
        removeTagsHtml(collection.data.syllabus).length > 0 &&
        collection.data.nodeType === 'LEARNING';
    const meetingStatus = false;

    // const verifyStatus = !singleLevelContent && (syllabusStatus || meetingStatus || Boolean(showInteractionDrawerButton) || attachmentStatus);
    const verifyStatus = syllabusStatus || meetingStatus || Boolean(showInteractionDrawerButton) || attachmentStatus;

    return verifyStatus ? (
        <div className="menuHeaderContent">
            <MenuHeaderContentView
                syllabusStatus={syllabusStatus}
                callSyllabus={callSyllabus}
                meetingStatus={meetingStatus}
                attachmentStatus={attachmentStatus}
                setIsOpenAttachmentSideBar={setIsOpenAttachmentSideBar}
                showInteractionDrawerButton={showInteractionDrawerButton}
                setIsOpen={setIsOpen}
            />
            {isOpenComments && <InteractionDrawer {...drawerContainer} isOpen={isOpenComments} handleClose={() => setIsOpen(false)} />}
            {attachmentStatus && (
                <AttachmentSideBar idTeam={idTeam} isOpen={isOpenAttachmentSideBar} handleClose={() => setIsOpenAttachmentSideBar(false)} />
            )}
        </div>
    ) : null;
};

const mapDispatchToProps = (dispatch) => ({
    updateConfigHeaderFooter: (item) => dispatch(updateConfigHeaderFooter(item)),
});

export default compose(withRouter, connect(null, mapDispatchToProps))(MenuHeaderContent);
