import './style.scss';
import React, { useState, useRef, useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import useCards from '../../../pctHooks/useCards';
import SVG from 'react-inlinesvg';
import useSrcSetVerify from '../../../pctHooks/useSrcSetVerify';

import { updatePreTest } from 'actions';

import {
    Duration,
    Label,
    Text,
    ProgressBar,
    Image,
    Mandatory,
    Sequential,
    ButtonCertificate,
    Registered,
    Like,
    ViewSyllabus,
    Preloader,
} from '../../atoms';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import ShareButton from 'pctComponents/organisms/ShareButton';
import { getProfileInfo } from 'services/utils';
import ModalEquivalencyPages from 'pctComponents/modals/ModalEquivalencyPages';
import NiceModal from '@ebay/nice-modal-react';
import { getEquivalences } from 'services/api';
import { Button } from 'react-bootstrap';

import { PreTestSVG } from '../../svg';

type idType = {
    id?: string;
};

type Props = {
    id: any;
    dataParent?: any;
    prerequisite?: boolean;
    collection: any;
    parameters?: any;
    preTest?: any;
    configPreTest?: {
        statusPreTest: Boolean | null;
    };
};

const CardBlock = (props: Props) => {
    const { collection, dataParent, preTest } = props;

    const { description, title, workload, mandatory } = collection.data;
    const { id } = useParams<idType>();
    const { equivalentNodeCode } = collection?.data?.nodeEnrollment || {};
    const hasEquivalence = Boolean(equivalentNodeCode);
    const setProgressStateRef = useRef(null as any);
    const progressInfoRef = useRef(null as any);
    const user = getProfileInfo();

    const {
        image,
        concluidedText,
        currentLabel,
        currentBreadText,
        isEnrollment,
        hasCertificate,
        initialProgressState,
        statusRegistered,
        classNotStartedYet,
        prerequisitesMet,
        handleCardMouseEvent,
        handleCertificateMouseEvent,
        statusElement,
        hasEnrollment,
        verifyCompletedStatus,
        nodeAvailable,
    } = useCards({
        collection,
        id,
        dataParent,
        setProgressStateRef,
        progressInfoRef,
        preTest,
    });

    const configPreTest = props.configPreTest?.statusPreTest || false;

    const mandatoryStatus = !!mandatory;
    const [progressInfo, setProgressState] = useState(initialProgressState());
    const [equivalencePages, setEquivalencePages] = useState([]);
    const [loadingEquivalencies, setLoadingEquivalencies] = useState(false);

    useEffect(() => {
        (async () => {
            if (!hasEquivalence) {
                return;
            }

            setLoadingEquivalencies(true);
            try {
                const {
                    data: { result },
                } = await getEquivalences(collection?.data?.idNode || 0);
                setEquivalencePages(result.map((page) => ({ title: page.origin.title })));
                setLoadingEquivalencies(false);
            } catch (error) {
                setLoadingEquivalencies(false);
            }
        })();
    }, [collection, equivalentNodeCode, user.idUser, hasEquivalence]);

    setProgressStateRef.current = setProgressState;
    progressInfoRef.current = progressInfo;

    const stsEnrollment = hasEnrollment() && collection.data?.nodeType !== 'ORGANIZATION';

    const format = 'landscape-xsmall';
    const imgBase = (statusElement() === 'ARCHIVED' || !nodeAvailable()) && image().image ? image().image : image();

    const srcSetVerify = useSrcSetVerify(imgBase, format);
    const addRulesRequisiteBlock = (prevItem) => {
        if (prevItem) {
            const { contentEnrollment, nodeEnrollment, questionnaire } = prevItem.data;
            const isContentIncomplete =
                prevItem.elementType === 'CONTENT' && (!contentEnrollment || !['COMPLETED', 'PASSED'].includes(contentEnrollment?.status));
            const isNodeIncomplete =
                prevItem.elementType === 'NODE' && (!nodeEnrollment || !['COMPLETED', 'PASSED'].includes(nodeEnrollment?.status));
            const isEvaluationIncomplete = prevItem.elementType === 'EVALUATION' && (!questionnaire || !questionnaire?.finishDate);
            if (isContentIncomplete || isNodeIncomplete || isEvaluationIncomplete) {
                return true;
            }
        }
        return false;
    };
    const preRequisiteBlockMet = useMemo(() => {
        if (dataParent?.node?.blocks) {
            const result = _.chain(dataParent.node.blocks).map('elements').flatten().value();

            const findIndex = result.findIndex((elem) => collection.idElement === elem.idElement);

            if (!findIndex) return false;
            return addRulesRequisiteBlock(result[findIndex - 1]) && !!collection.data.sequential;
        }
        return false;
    }, [collection]);

    const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+/;

    const isYouTubeLink = (link) => {
        return youtubeRegex.test(link);
    };

    const showEquivalenceModal = async () => {
        NiceModal.show(ModalEquivalencyPages, {
            obj: {
                pages: equivalencePages,
            },
        });
    };

    if (loadingEquivalencies) {
        return <>{loadingEquivalencies && <Preloader />}</>;
    }

    const handleCard = (e: React.MouseEvent<HTMLElement>) => {
        if (configPreTest || !!isEnrollment().processing) {
            e.stopPropagation();
            return;
        }

        handleCardMouseEvent(e);
    };

    const handleEquivalence = (e: React.MouseEvent<HTMLElement>) => {
        if (configPreTest) {
            e.stopPropagation();
            return;
        }

        if (hasEquivalence) {
            showEquivalenceModal();
        }

        e.stopPropagation();
    };

    const handleCertificate = (e: React.MouseEvent<HTMLElement>) => {
        if (configPreTest || !!isEnrollment().processing) {
            e.stopPropagation();
            return;
        }

        handleCertificateMouseEvent(e);
    };

    return (
        <div
            className={`pctMolecule pctCard pctCardBlock 
            ${
                (preRequisiteBlockMet === true ||
                    prerequisitesMet() === false ||
                    statusRegistered() === 'inactive' ||
                    statusElement() === 'ARCHIVED' ||
                    classNotStartedYet()) &&
                'pctCardBlockLock'
            }
            ${!nodeAvailable() ? 'pctCardBlockLock02' : ''}`}
            style={{
                cursor: isEnrollment().processing ? 'not-allowed' : 'pointer',
            }}
            onClick={handleCard}
        >
            {collection.data.image && collection.elementType !== 'EVALUATION' && statusElement() !== 'ARCHIVED' && nodeAvailable() !== false && (
                <div className="thumbContainer">
                    <Registered status={statusRegistered()} />
                    <div
                        className="thumb"
                        data-aspectratio={format}
                        style={{
                            opacity: isEnrollment().processing ? 0.2 : 1,
                        }}
                    >
                        <Image path={image()} pathSet={srcSetVerify} />
                    </div>
                </div>
            )}

            {!collection.data.image && collection.elementType !== 'EVALUATION' && nodeAvailable() && statusElement() !== 'ARCHIVED' && (
                <div className="thumbContainer">
                    <Registered status={statusRegistered()} />
                    <div
                        className="thumb"
                        data-aspectratio={format}
                        style={{
                            opacity: isEnrollment().processing ? 0.2 : 1,
                        }}
                    >
                        <div className="thumb">{image() && <SVG src={image()} />}</div>
                    </div>
                </div>
            )}

            {/* Verificacao do tipo EVALUATION com imagem */}
            {/* {image() && collection.elementType === 'EVALUATION' && collection.data.image && (
                <div className="thumbContainer">
                    <Registered status={statusRegistered()} />
                    <div
                        className="thumb"
                        data-aspectratio={format}
                        style={{
                            opacity: isEnrollment().processing ? 0.2 : 1,
                        }}
                    >
                        <Image path={image()} pathSet={srcSetVerify} />
                    </div>
                </div>
            )} */}

            {/* Verificacao do tipo EVALUATION sem imagem */}
            {collection.elementType === 'EVALUATION' && (
                <div className="thumbContainer">
                    <Registered status={statusRegistered()} />
                    <div
                        className="thumb"
                        data-aspectratio={format}
                        style={{
                            opacity: isEnrollment().processing ? 0.2 : 1,
                        }}
                    >
                        {image() && <SVG src={preTest ? PreTestSVG() : image()} />}
                    </div>
                </div>
            )}

            {statusElement() === 'ARCHIVED' && (
                <div className="thumbContainer">
                    <div className="thumb" data-aspectratio={format}>
                        {image().svg && <SVG src={image().svg} />}

                        {!collection.data.image && <div className="thumb">{image() && <SVG src={image().image} />}</div>}

                        {collection.data.image && <Image path={image().image} pathSet={srcSetVerify} placeholder={true} />}
                    </div>
                </div>
            )}

            {!nodeAvailable() && (
                <div className="thumbContainer">
                    <div className="thumb" data-aspectratio={format}>
                        {image().svg && <SVG src={image().svg} />}

                        {/* {!collection.data.image &&
              <div className="thumb">{image() && <SVG src={image().image} />}</div>
            } */}

                        {collection.data.image && <Image path={image().image} pathSet={srcSetVerify} placeholder={true} />}
                        <div className="cardInfo">
                            <h3>Acesso Restrito</h3>
                            <p>
                                Este <span className="destaque">conteúdo é restrito</span> para um grupo específico e você não tem permissão para
                                visualizar.
                            </p>
                        </div>
                    </div>
                </div>
            )}

            <div
                className={`infosContainer 
        ${!image() ? 'infosContainerImgNull' : ''}
        ${!stsEnrollment ? 'infosContainerEnrollmentNull' : ''}
        
        `}
                style={{
                    opacity: isEnrollment().processing ? 0.2 : 1,
                }}
            >
                {<Text text={currentBreadText()} component={'small'} className="breadCard" />}
                {<Text component="h3" text={title} />}
                {description && description !== 'N / A' && description !== 'N / D' && description !== 'N/A' && description !== 'N/D' && (
                    <Text component="p" text={description} />
                )}

                {stsEnrollment && (
                    <div className="progressConatainer">
                        <ProgressBar percentage={progressInfo} status={!preTest ? statusElement() : ''} />
                    </div>
                )}

                <div className="footerInfos">
                    <div className="leftInfo">
                        {<Label text={currentLabel()} className="label" />}
                        {
                            <div className="dataInfos">
                                {<Duration text={workload} timeFormat={'seconds'} className="duration-mobile" />}

                                {stsEnrollment && statusElement() !== 'ARCHIVED' && (
                                    <Button className={hasEquivalence ? 'clickable-label' : ''} onClick={handleEquivalence}>
                                        <Text component="small" className="dis" text={concluidedText()} />
                                    </Button>
                                )}
                                <div className="info-buttons">
                                    {statusElement() !== 'ARCHIVED' && hasCertificate() && (
                                        <ButtonCertificate verifyCompletedStatus={verifyCompletedStatus()} onClick={handleCertificate} />
                                    )}

                                    <ViewSyllabus collection={collection} />

                                    <Like
                                        css={{
                                            cursor: isEnrollment().processing ? 'not-allowed' : 'pointer',
                                        }}
                                        disabled={!!isEnrollment().processing || !!configPreTest}
                                        collection={collection}
                                        statusRegistered={statusRegistered()}
                                        className="likeSmall"
                                    />
                                </div>
                            </div>
                        }
                    </div>

                    {/* {(star || award)&&<div className='rightInfo'>
                        <div className='baseInfo'>
                            {<Star text={star} />}
                            {<Award text={award} />}
                        </div>
                    </div>} */}
                </div>
            </div>

            {statusElement() !== 'ARCHIVED' && (
                <div className="containerMandatoryLock">
                    {isYouTubeLink(collection.data.contentLocation) && Boolean(collection.data.shareable) ? (
                        <ShareButton
                            className="ShareButtonCardStandard"
                            callEvent={(e) => (isEnrollment().processing ? () => {} : e.stopPropagation())}
                            link={collection.data.contentLocation}
                            idContent={collection.idElement}
                            idUser={user.idUser}
                        />
                    ) : (
                        ''
                    )}
                    <Mandatory status={mandatoryStatus} />
                    <Sequential status={!isEnrollment().processing && prerequisitesMet() && !preRequisiteBlockMet} />
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (store) => ({
    configPreTest: store.configPreTestState,
});

const mapDispatchToProps = (dispatch) => ({
    updatePreTest: (item) => dispatch(updatePreTest(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CardBlock);
